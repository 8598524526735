import React from 'react';
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faGithub,
    faInstagram,
    faLinkedin,
    faStackOverflow,
    faTwitter,
    faYoutube
} from "@fortawesome/free-brands-svg-icons";
import ContactForm from "./Contact";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const App: React.FC = () => {
    return (
        <div className="App">
            <Navbar bg="light" expand="lg" className="mb-3">
                <Container>
                    <Navbar.Brand href="https://www.engineal.com">Aaron Lucia</Navbar.Brand>
                    <Navbar.Toggle aria-controls="navigation"/>
                    <Navbar.Collapse id="navigation">
                        <Nav className="ms-auto">
                            <NavDropdown title="Aaron's sites" id="sites-dropdown">
                                <NavDropdown.Item href="https://www.engineal.com">Aaron Lucia</NavDropdown.Item>
                                <NavDropdown.Item href="https://minecraft.engineal.com">Minecraft</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container>
                <div className="p-5 mb-4 bg-light rounded-3">
                    <h1>Aaron Lucia</h1>
                    <p className="lead">Software Engineer</p>
                    <hr className="my-4"/>
                    <div>Please pardon this website as it's still in development.</div>
                </div>
                <section id="contact">
                    <h1>Contact</h1>
                    <ContactForm/>
                </section>
            </Container>
            <footer>
                <Container>
                    <div className="row">
                        <div className="col-sm-9 text-muted">
                            &copy; Aaron Lucia 2016 - 2022.
                        </div>
                        <div className="col-sm-3 text-end">
                            <a href="https://facebook.com/engineal" target="_blank noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebook} size="2x" color="grey" className="px-1"/>
                            </a>
                            <a href="https://instagram.com/engine_al" target="_blank noopener noreferrer">
                                <FontAwesomeIcon icon={faInstagram} size="2x" color="grey" className="px-1"/>
                            </a>
                            <a href="https://twitter.com/engine_AL" target="_blank noopener noreferrer">
                                <FontAwesomeIcon icon={faTwitter} size="2x" color="grey" className="px-1"/>
                            </a>
                            <a href="https://linkedin.com/in/engineal" target="_blank noopener noreferrer">
                                <FontAwesomeIcon icon={faLinkedin} size="2x" color="grey" className="px-1"/>
                            </a>
                            <a href="https://youtube.com/@engineAL"
                               target="_blank noopener noreferrer">
                                <FontAwesomeIcon icon={faYoutube} size="2x" color="grey" className="px-1"/>
                            </a>
                            <a href="https://stackoverflow.com/users/3229822/engineal"
                               target="_blank noopener noreferrer">
                                <FontAwesomeIcon icon={faStackOverflow} size="2x" color="grey" className="px-1"/>
                            </a>
                            <a href="https://github.com/engineal" target="_blank noopener noreferrer">
                                <FontAwesomeIcon icon={faGithub} size="2x" color="grey" className="px-1"/>
                            </a>
                        </div>
                    </div>
                </Container>
            </footer>
        </div>
    );
};

export default App;
