import React, {ChangeEventHandler, FormEventHandler, useState} from 'react';
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import axios from 'axios';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const defaultMessage = {
    name: "",
    email: "",
    subject: "",
    message: "",
};
type Message = Readonly<typeof defaultMessage>;
type Props = Partial<Message>

const sendMessage = async (message: Message, executeRecaptcha: (action?: string) => Promise<string>) => {
    const token = await executeRecaptcha('contact');

    await axios.post("/api/contact", message, {
        headers: {'X-reCAPTCHA-Token': token}
    });
};

const ContactForm: React.FC<Props> = (props) => {
    const {executeRecaptcha} = useGoogleReCaptcha();

    const [message, setMessage] = useState({
        ...defaultMessage,
        ...props
    });
    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(false);
    const [error, setError] = useState('');

    const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
        const field = event.target.name;
        const value = event.target.value;
        setMessage(prevMessage => ({...prevMessage, [field]: value}));
        setError('');
    };

    const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        setSending(true);
        setError('');
        sendMessage(message, executeRecaptcha!).then(() => {
            console.log("Message sent!");
            setSent(true);
        }).catch((e) => {
            console.error(e);
            setError(e?.response?.data?.error?.message ? e.response.data.error.message :
                e?.response ? e.response.statusText : 'An error occurred sending the message.');
        }).finally(() => {
            setSending(false);
        });
        event.preventDefault();
    };

    const handleReset: FormEventHandler<HTMLFormElement> = (event) => {
        setMessage(defaultMessage);
    };

    if (!sent) {
        return (
            <form id="contact-form" data-testid="contact-form" onSubmit={handleSubmit} onReset={handleReset} >
                <div className="row mb-3">
                    <label htmlFor="name" className="col-sm-2 form-label col-form-label">Name</label>
                    <div className="col-sm-10">
                        <input type="text" name="name" id="name" className="form-control" placeholder="Name"
                               value={message.name} onChange={handleChange} disabled={sending} required/>
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="email" className="col-sm-2 form-label col-form-label">Email address</label>
                    <div className="col-sm-10">
                        <input type="email" className="form-control" name="email" id="email" placeholder="Email"
                               value={message.email} onChange={handleChange} disabled={sending} required/>
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="subject" className="col-sm-2 form-label col-form-label">Subject</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject"
                               value={message.subject} onChange={handleChange} disabled={sending} required/>
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="message" className="col-sm-2 form-label col-form-label">Message</label>
                    <div className="col-sm-10">
                    <textarea rows={4} className="form-control" name="message" id="message" placeholder="Message"
                              value={message.message} onChange={handleChange} disabled={sending} required/>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-sm-10 offset-sm-2">
                        <button type="submit" className="btn btn-primary mx-1" disabled={sending}>Submit {sending ? <FontAwesomeIcon icon={faSpinner} pulse={true} /> : null}</button>
                        <button type="reset" className="btn btn-secondary mx-1" disabled={sending}>Clear</button>
                        {error}
                    </div>
                </div>
            </form>
        );
    } else {
        return (
            <div>
                <h4>Message Sent!</h4>
            </div>
        )
    }
};

export default ContactForm;
